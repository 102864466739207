<template>
  <div>

    <v-dialog
      v-model="dialog"
      width="680px"
      persistent
      overlay-color="transparent"
    >
      <FormUserProductsForm :id="user_product_id" :values="values" :loaded_values="loaded_values" @update="updateUserProductForm" @close="closeDialog" />
    </v-dialog>

    <v-row class="mt-0">
      <v-col cols="12">
        <div>
          <span class="primary--text">{{$t('USER_PRODUCT.TITLES')}}</span>
          <v-text-field
            name="form_fields"
            style="display:none;"
            :value="loaded_user_products && loaded_user_products.length > 0"
            :rules="[$rules.required]"
          />
        </div>

        <div class="mb-4 mt-2 d-flex">
          <v-btn
            small
            rounded
            depressed
            class="mr-4"
            color="secondary black--text"
            @click="newUserProduct"
          >
            <v-icon size="16" class="mr-2">mdi-plus</v-icon>
            {{$t('COMMON.ADD')}}
          </v-btn>

        </div>
          <template v-if="loaded_user_products && loaded_user_products.length > 0">
            <v-card outlined>
              <v-list class="pa-0 scroll height-max-30vh position-relative">

                <Loading :value="loading" />

                  <template v-for="(item, i) in loaded_user_products">
                  <v-list-item
                    :key="`cont${i}`"
                    :class="[item.status === -1 ? 'muted' : '']"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{item.product.title}}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-chip
                          x-small
                          color="red white--text"
                          v-if="item.status === -1"
                          class="mr-2"
                        >
                          {{ $t('USER_PRODUCT.CANCELED') }}
                        </v-chip>

                        <v-chip
                          x-small
                          color="red white--text"
                          v-else-if="isFinished(item)"
                          class="mr-2"
                        >
                          {{ $t('USER_PRODUCT.FINISHED') }}
                        </v-chip>

                        <v-icon class="mr-2" size="16">mdi-calendar-clock</v-icon>
                        <small>
                          <strong class="mr-4" v-if="new Date(item.end_date) > new Date() && !isFinished(item)">{{ $t('USER_PRODUCT.WILL_FINISHED', {date: $moment(item.end_date).fromNow() }) }}</strong>
                          {{ new Date(item.start_date).toLocaleDateString() }}
                          <v-icon class="mr-2 ml-2" size="14">mdi-arrow-left-right</v-icon>
                          {{ new Date(item.end_date).toLocaleDateString() }}
                        </small>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <div class="text-small pt-2">{{$t('COMMON.LAST_UPDATE')}} {{ new Date(item.updated_at).toLocaleString() }}</div>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action />

                    <v-list-item-action>
                      <div class="">
                        <v-btn icon @click="removeUserProduct(item.id)" :disabled="item.status !== 1">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn icon @click="editUserProduct(item.id)" :disabled="item.status !== 1">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="`div${i}`" />
                </template>

              </v-list>
            </v-card>
          </template>
      </v-col>
    </v-row>
  </div>

</template>

<script>

import FormUserProductsForm from './FormUserCompact.products.form.vue'
import Loading from '@/components/Loading'


export default {
  name:"FormUserCompactProducts",
  components: {
    FormUserProductsForm,
    Loading
  },
  data: () => ({
    dialog: false,
    valid: false,
    loading: false,
    use_xtramile_account: false,
    user_product_id: undefined,
    loaded_user_products: [],
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    },
    loaded_values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  watch: {
    methods (n,o) {
      n.filter(v => this.channel.methods.indexOf(v) !== -1)
      if (n && n.length !== o.length) {
        this.$emit('input', {methods: n})
      }
    },
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    },
    loaded_values: {
      handler () {
        this.loadUserProductItems()
      },
      deep: true,
    },

  },
  created () {
    this.attribValues()
    this.loadUserProductItems()
  },
  methods: {

    isFinished (item) {
      let about_time = item.no_limit_time ? false : new Date(item.end_date) < new Date()
      let about_value = item.no_limit_value ? false : item.type === 'credit' ? item.commited >= item.value : item.used >= item.value
      return about_time && about_value
    },


    updateUserProductForm(id) {
      this.loadUserProductItems()
      if (id) this.user_product_id = id
    },

    removeUserProduct(id) {
      if (id)
        return this.$services.api_user.user_product
          .remove({ where: { id: id } })
          .then((response) => {
            this.loadUserProductItems()
          })
    },

    closeDialog () {
      this.dialog = false
    },

    newUserProduct() {
      this.user_product_id = undefined
      this.dialog = true
    },

    editUserProduct(id) {
      this.user_product_id = id
      this.dialog = true
    },

    save_start_date(date) {
      this.$refs.menu_start_date.save(date)
    },

    save_end_date(date) {
      this.$refs.menu_end_date.save(date)
    },


    loadUserProductItems() {
      this.loading = true
      if (this.loaded_values.id)
        return this.$services.api_user.user_product
          .search({
            where: {user_id: this.loaded_values.id, product: true},
          })
          .then(response => {
            if (response && response.data.length > 0) {
              this.loaded_user_products = response.data
            } else {
              this.loaded_user_products = []
            }
          })
          .finally(() => {
            this.loading = false
          })
    },

    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        this[key] = values[key]
      })
    },


  }
}
</script>
